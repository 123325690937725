@import '../../style/constant';
@import '../../style/mixins';

.top-bar {
  position: relative;
  display: block;
  margin: 0;
  height: 35px;
  background: #747474 url(../../images/new/hours_bg.gif) repeat-x;
  color: #fff;
  display: flex;
  width: 100%;
  overflow: hidden;
  margin-left: 3.5rem;
  .middle-content {
    width: 7200px;
    padding-left: 0;
    position: absolute;
    span {
      width: 142px;
      display: inline-block;
      line-height: 35px;
    }
  }
}

.table-layout {
  margin-top: -0.5rem;
  .prev-btn {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: #747474 url(../../images/new/hour2_left.gif) 0 0 no-repeat;
    border-right: 1px solid #fff;
    height: 35px;
    width: 15px;
    z-index: 1;
  }
  .next-btn {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    right: 0px;
    background: #747474 url(../../images/new/hour2_right.gif) 0 0 no-repeat;
    border-left: 1px solid #fff;
    height: 35px;
    width: 15px;
  }
  .channel-list {
    padding-left: 3.5rem;
    width: 100%;
    overflow: hidden;
    .left-logo {
      position: absolute;
      left: -3.5rem;
      margin-top: 10px;
      img{
        max-width: 50px;
        max-height: 40px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        list-style: none;
      }
    }
    .channel {
      margin-top: .5rem;
      // padding:0 10px;
      background: url('../../images/new/grid_bg.gif') 0 0 repeat-x;
      position: relative;
      height: 50px;

      .prev-btn {
        background: #747474 url(../../images/new/grid_button_left.gif) 0 0 no-repeat;
        border-right: 1px solid #fff;
        height: 50px;
      }
      .next-btn {
        background: #747474 url(../../images/new/grid_button_right.gif) 0 0 no-repeat;
        border-left: 1px solid #fff;
        height: 50px;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        position: absolute;
        width: 7200px;
        li {
          list-style: none;
          font-size: 10px;
          line-height: 14px;
          padding: 5px;
          border-left: 1px solid #fff;
          height: 50px;
          min-width: 200px;
          align-content: center;
          background: url('../../images/new/grid_program_bg.gif') 0 0 repeat-x;
          color: #fff;
          h3{
            font-size: 10px;
            color: #fff;
            .time{
              font-weight: 400;
            }
          }
          &.active {
            background: url('../../images/new/grid_program_active_bg.gif') 0 0 repeat-x;
            color: #fff;
          }
        }
      }
    }

  }
}

.timeline-channel-list {
  width: 100%;
  position: relative;
  height: 50px;
  overflow: hidden;
  ul {
    li {
      cursor: pointer;
      &.active {
        text-align: right;
      }
      .status {
        color: #ed7635;
        display: inline-block;
      }
      .program {
        display: block;
        font-weight: 900;
      }
    }
  }
}

@include respond-below(xs) {
  .top-bar {
    // margin-left: 0;
  }
  .table-layout {
    margin-right: 0;
    margin-left: 0;
  }

}