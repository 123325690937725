@import "../../style/constant.scss";
@import "../../style/mixins.scss";

.provider-section {
  padding-top: 0;
  position: relative;
  margin: 0 -15px;
  .title {
    border: 0;
    margin-bottom: 10px;
    font-size: 20px;
    color: #222;
    display: block;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    color: #747474;
  }
  .choose-btn {
    display: block;
    border: none;
    border-radius: 5px;
    padding: 1em 1em;
    background: #a0295b;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  margin-right: 15px;
    // position: absolute;
    // left: 0;
    // top: 0;
  }
}
.providerList.providerList.providerList {
  padding: 0;
  height: 70px;
  min-height: 70px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  .container {
    position: relative;
    height: 50px;
  }
  .scroll-nav{
   display: flex;
  }
  ul {
    justify-content: left;
    li {
      margin: 0 3px;
      opacity: 1;
      border:1px solid #ccc;
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        color: #fff;
        padding: 0;
        display: block;
        
        // border:1px solid gray;
        img {
          max-width: 40px;
          vertical-align: middle;
        }
        span{
          transition: transform .2s;
        }
        &:hover{
          span{
            transform: scale(1.2);
          }
        }
        
      }
    }
    &.provider-selected {
      li {
        opacity: .5;
        &.selected {
          opacity: 1;
        }
      }
    }

    .selected {
      opacity: 1;
      border:2px solid #a0295b;
      border-radius: 5px;
    }
  }
  .prev-link,
  .next-link {
    position: absolute;
    color: #fff;
    top: 8px;
    padding: 0.3rem 1rem;
    margin-top: -0.5rem;
  }
  .prev-link {
    left: 15px;
  }
  .next-link {
    right: 15px;
  }
}

@include respond-below(md) {
  .providerList.providerList.providerList {
    .container {
      position: relative;
      width: 100%;
      max-width: 100%;
      overflow-y: auto;
    }
    .prev-link {
      left: 10px;
    }
    .next-link {
      right: 10px;
    }
    .scroll-nav{
      justify-content: flex-end;
    }
    ul {
      min-width: calc(100% - 180px);
      padding: 0px 1rem;
      height: 50px;
      overflow-y: hidden;
      overflow-x: scroll;
      flex-wrap: unset;
      flex: 0 0 calc(100% - 180px);
    }
    .choose-btn{
      font-size: 11px;
    width: 146px;
    padding: 11px;
    position: absolute;
    left: 20px;
    }
    .scroll-nav {
      width: 100%;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@include respond-below(xs) {
  .providerList.providerList.providerList {
    .container {
      position: relative;
      width: 100%;
      max-width: 100%;
      overflow-y: auto;
    }
    .prev-link {
      left: 10px;
    }
    .next-link {
      right: 10px;
    }
    .scroll-nav{
      justify-content: flex-end;
    }
    ul {
      min-width: 100%;
      padding: 0px;
      overflow-y: hidden;
      overflow-x: scroll;
      flex-wrap: unset;
      flex: 0 0 100%;
      li{
        margin: 0 3px;
        height: 40px;
        width: 40px;
        flex: 0 0 40px;
        a{
          span{
            height: 30px;
            width: 30px;
          }
        }
      }

    }
    .choose-btn{
    display: none;
    }
    .scroll-nav {
      width: 100%;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }


  
}
