@import '../style/constant.scss';
@import '../style/mixins.scss';
.main-content{
    overflow-x: hidden;
    .container{
        min-height: 800px;
        background: #fff;
        height: 100%;
        padding:3rem 30px;
        position: relative;
        margin-bottom: 1rem;
        margin-top: 16px;
    }
}
.sticky-ads{
    display: none;
}

ul,h2{
    margin-bottom: 2rem;
}

.info-bar{
    // position: absolute;;
    // margin: -3rem -15px 3rem -15px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5b544f+4,828c95+14,b5bdc8+100 */
background: rgb(91,84,79); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(91,84,79,1) 4%, rgba(130,140,149,1) 14%, rgba(181,189,200,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(91,84,79,1) 4%,rgba(130,140,149,1) 14%,rgba(181,189,200,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(91,84,79,1) 4%,rgba(130,140,149,1) 14%,rgba(181,189,200,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b544f', endColorstr='#b5bdc8',GradientType=0 ); /* IE6-9 */
    padding:5px 0;
    .category-drop-down{
        margin-top: 4px;
    }

    top:0;
        p{
        margin: 0;
        line-height: 2.2rem;
        font-size: 0.8rem;
        span{
            margin-right: 10px;
            padding-right: 10px
        }
    }
    > div{
        display: flex;
    }

}

.grey-bg{
    background: #e9e9e9;
    padding: 1rem;
}
.ml-1{
    margin-left: 1rem;
}

@include respond-above(md) {
    .main-content{
        .container{
            padding:1rem;
        }
    }
    .info-bar{
        .container{
            padding: 0 15px;
        }
    }
}
@include smallDesktopAndMobile{
    iframe{
        max-width: 100%;
        img{
            max-width: 100%;
        }
    }
    .top-add-container{
        max-width: 100%;
        min-height: 320px;
    }
   
}
@include largeDesktop {
    .top-add-container{
        // min-height: 240px;
        >div{
            // position: absolute;
            // left:0;
            // right: 0;
            // margin: auto;
            // iframe{
            //     margin-left:-200px;
            // }
        }
    }
    .outer-container{
        max-width: 980px;
        margin: 0 auto;
        background: #fff;
        position: relative;
        .main-inner-container{
            float: left;
            max-width: 980px;
            min-width: 980px;
        }
    }
    .sticky-ads{
        display: block;
        // width: 200px;
        position: absolute;
        left: 1000px;
        .secondAds{
            position: fixed;
            top:0;
        }
    }
    .side-ads{
        position: absolute;
        left: 1000px;
        >div{
            position: static;
            top:0;
        }
    }
  }

@include mobile{
    .sticky-ads,
    .side-ads{
        display: none;
    }
}


@media all and (max-width: 1229px) {
    .sticky-ads,
    .side-ads{
        display: none;
    }
}