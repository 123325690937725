@import "../style/constant.scss";
@import "../style/mixins";

.program-list {
  margin: -1rem 0 1rem -0.3rem;
  padding: 0;
  list-style: none;

  &.first-section {
    margin-top: 3rem;
  }

  .top-logo {
    display: flex;
    margin-bottom: 10px;
    position: absolute;
    top: -2rem;
    left: 0;

    img {
      max-height: 40px;
      margin-top: -5px;
      max-width: 100%;
    }

    h2 {
      font-size: 14px;

      a {
        color: #575757;
        font-weight: 900;
        font-size: 14px;
        text-decoration: none;
      }
    }

    span {
      margin: 0 5px;
    }

    .name {
      color: #575757;
      font-weight: 900;
    }
  }

  .item {
    color: #000;
    font-size: 0.8rem;
    padding: 0;
    background: #fff;
    position: relative;
    margin: 0 2px 4rem 2px;
    width: calc(33.333% - 4px);
    max-width: calc(33.333% - 4px);
    min-height: 120px;
    overflow: visible;

    &::before {
      content: "";
      height: 1px;
      width: 90%;
      position: absolute;
      left: 10%;
      bottom: 0;
      background-color: #ebebeb;
    }

    ul {
      padding: 10px 0.2rem;
      list-style: none;

      li {
        cursor: pointer;
        font-size: 12.5px;
        line-height: 18px;
        h3 {
          font-size: 13px;
          font-weight: 400;
          color: #000;
          display: inline-block;
          width: calc(100% - 40px);
        }

        &.series {
          h3, span {
            color: #30609e;
          }
        }

        &.sports {
          h3, span {
            color: #e59449;
          }
        }

        &.film {
          h3, span {
            color: #cc2336;
          }
        }

        &.inactive {
          .program {
            color: #9b9b9b;
          }
        }

        &.viewAll {
          text-decoration: underline;
          font-weight: 700;
        }

        &.active {
          background: #61b8e3;
          color: #fff;

          .program {
            color: #fff;
            font-weight: 900;
          }
        }
      }

      .time {
        // color: #b4b4b4;
        display: inline-block;
        vertical-align: middle;
      }

      .status {
        color: #ed7635;
        display: inline-block;
        vertical-align: middle;
      }

      .program {
        display: inline-block;
        max-width: calc(100% - 51px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
  }
}

.channel-list {
  background: #fff;
  margin: 15px 0 0 0;
  padding: 15px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      list-style: none;
      font-size: 11px;
      font-family: Trebuchet MS;

      a {
        color: #000;
        font-weight: 600;
      }
    }
  }
}

.grid-add-container {
  margin: -3rem 0 5rem -15px;
  // #cncpt-lb1{
  //     max-width: 100%;

  // }
  // iframe{
  //     max-width: 100%;
  // }
}

.program-type-heading {
  margin: 1rem 0;

  h1 {
    min-height: 28px;
    font-size: 20px;
    line-height: 1.2;
    text-transform: none;
    font-weight: 700;
    padding-bottom: 0;
    color: #333;
  }

  p {
    font-size: 13px;
    line-height: 1.4;
    font-weight: 400;
    display: block;
  }
}

@include tablet {
  .program-list {
    .item {
      width: calc(33.333% - 12px);
      max-width: calc(33.333% - 12px);
    }
  }
}

@include respond-below(sm) {
  .program-list {
    .item {
      width: calc(33.333% - 12px);
      max-width: calc(33.333% - 12px);
    }

    // li.item:last-child{
    //   width: 100%;
    //   max-width: 100%;
    //   flex: 0 0 100%;

    // }
  }
  .navbar {
    justify-content: flex-end;
  }
}

@include mobile {
  .grid-add-container {
    min-height: 320px;
    margin-left: 0;
  }
}

@include respond-below(xs) {
  .program-list {
    .item {
      width: calc(50% - 12px);
      max-width: calc(50% - 12px);

      .top-logo {
        top: -2rem;
      }
    }
  }
}
