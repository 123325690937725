@import "../../style/mixins.scss";
@import "../../style/constant.scss";

.stream-listing.stream-listing.stream-listing {
  margin: 0 -5px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 1 0 calc(25% - 10px);
    max-width: calc(25% - 10px);
    margin: 0 5px 10px 5px;
    background-color: #ebe7e7;
      border-bottom: 2px solid #979797;
    // display: inline-block;
    .top-image-section {
      position: relative;
      height: 165px;
      width: 100%;
      transition-duration: 0.5s;
      
      &:hover {
        .user-action {
          transition-delay: 0.2s;
          opacity: 1;
        }
      }
      .imageContainer {
        border-radius: 0;
        max-width: 100%;
        min-height: 100%;
        overflow: hidden;
        width: 100%;
        height: 100%;
        // background-size: cover;
        // background-position: center top;
        img{
          max-height: 100%;
        }
      }
      .user-action {
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8));
        box-shadow: 0 2px 2px 0;
        width: 100%;
        border-radius: 0 0 5px 5px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition-duration: 0.5s;
        opacity: 0;
        padding: 40px 10px 10px 10px;
        display: flex;
        align-items: flex-end;
        z-index: 1;
        .user-action{
          height: 24px;
        }
        .like-icon,
        .dislike-icon,
        .heart-icon,
        .addTOlist-icon2{
          height: 24px;
          width: 24px;
          margin-right: 10px;
        }
        .heart-icon,
        .addTOlist-icon2 {
          position: absolute;
          bottom: 10px;
          right: 0;
        }
        .addTOlist-icon2 {
          right: 0;
        }
        
      }
      .play-icon{
        height: 30px;
        width: 30px;
        margin-right:0;
        right:5px;
        position: absolute;
        top: 5px;
        z-index: 9;
      }
    }

    .info {
      height: calc(100% - 165px);
      background-color: #ebe7e7;
      padding: 10px;
      position: relative;
      .provider-badge {
        background: #222;
        color: #fff;
        font-size: 10px;
        padding: 2px 4px;
        display: inline-block;
        margin-bottom: 8px;
        text-transform: capitalize;
        letter-spacing: 0.5px;
        margin-left: 5px;
        &:first-child{
          margin-left: 0;
        }
      }
      .genres-badge {
        background: #222;
        color: #fff;
        font-size: 10px;
      }
      span {
        font-size: 12px;
        color: #646464;
        display: inline-block;
        margin-bottom: 10px;
      }
      p {
        font-size: 12px;
        line-height: 14px;
        color: #000;
        margin-bottom: 0;
      }
      a {
        font-size: 11px;
        font-weight: bold;
        position: absolute;
        left: 10px;
        bottom: 10px;
        padding: 3px 10px;
        background: #a0295b;
        color: #fff;
      }
      .badge-info {
        padding-left: 15px;
        display: inline-block;
        text-align: left;
        background: url("../../images/imdb.svg") no-repeat 0 3px;
      }
    }
    .heading-sec {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 14px;
      color: #000;
      margin: 0;
      height: 34px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    // &:first-child{
    //   .actions{
    //     left:61%;
    //   }
    // }
    // &:last-child{
    //   .actions{
    //     left:41%;
    //   }
    // }
  }
}
.justify-content-right.justify-content-right.justify-content-right {
  justify-content: right;
}

.live-badge{
  position: absolute;
  z-index: 9;
  background: #a0295b;
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  top:5px;
  left:5px;
  font-size: 10px;
}
.view-all-btn {
  display: block;
  float: right;
  margin-bottom: 10px;
  padding: 5px 30px 5px 10px;
  background: #a0295b;
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  letter-spacing: 0.5px;
  position: relative;
  font-weight: bold;
  &::before {
    
    padding-left: 8px;
    content: "\0279E";
    cursor: pointer;
    display: block;
    position: absolute;
    right: 5px;
    font-size: 20px;
    top:0;
    -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
  }
  &:hover{
    color: #fff;
    text-decoration: none;
    &::before {
      -webkit-transform: translateX(4px);
      -moz-transform: translateX(4px);
      -ms-transform: translateX(4px);
      -o-transform: translateX(4px);
      transform: translateX(4px)
    }
  }
}
.custom-selectBox {
  border: 1px solid #ccc;
  height: 28px;
  padding: 0 5px;
  max-width: 200px;
  margin: 0 10px;
  > div {
    position: relative;
    padding-top: 3px;
    select {
      -webkit-appearance: none;
      border: 0;
      padding-right: 20px;
      font-size: 12px;
      max-width: 100%;
    }
    &::before {
      content: "";
      height: 9px;
      width: 14px;
      background-image: url(../../images/downArrow.svg);
      background-size: 14px 9px;
      background-repeat: no-repeat;
      background-position: right top;
      position: absolute;
      right: 0;
      top: 7px;
    }
  }
}
.topSection {
  display: flex;
  justify-content: space-between;
  
  .rightSection {
    display: flex;
    justify-content: space-between;
  }
}
.headingWithBorder {
  border-bottom: 2px solid #222;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.genre {
  position: relative;
  select {
    -webkit-appearance: none;
    border: 0;
    padding-right: 20px;
    font-size: 11px;
    max-width: 100%;
  }
  &::before {
    content: "";
    height: 9px;
    width: 14px;
    background-image: url(../../images/downArrow.svg);
    background-size: 14px 9px;
    background-repeat: no-repeat;
    background-position: right top;
    position: absolute;
    right: 0;
    top: 5px;
  }
}

.headingH2 {
  color: #000;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #979797;
  padding: 20px 0 10px 0;
  margin-bottom: 20px;
  font-weight: 900;
}

.sorting {
  margin-bottom: 20px;
  span {
    padding: 0 10px;
    border-left: 1px solid #000;
    a {
      color: #000;
    }
    &:first-child {
      padding-left: 0;
      border-left: 0;
    }
    &.active {
      font-weight: bold;
    }
  }
}

.back-btn {
  font-size: 16px;
  margin: 15px 0;
  display: inline-block;
  font-weight: bold;
}

.large-image {
  max-height: 480px;
  margin-bottom: 20px;
  overflow: hidden;
  img {
    height: 100%;
  }
}

.heading1 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
}

.blog-text {
  h1,
  h2,
  h3,
  h4 {
    color: #333;
    margin-bottom: 15px;
  }
  h1 {
    font-size: 24px;
    line-height: 28px;
  }
  h2 {
    font-size: 22px;
    line-height: 26px;
  }
  h3 {
    font-size: 18px;
    line-height: 22px;
  }
  h4 {
    font-size: 16px;
    line-height: 20px;
  }
  p {
    color: #6d6d6d;
    font-size: 14px;
    line-height: 18px;
    //h2 {
    //  color: #6D6D6D;
    //  font-size: 14px;
    //  line-height: 18px;
    //  font-weight: normal;
    //  margin-bottom: 0;
    //}
  }
}

.program-details {
  max-width: 980px;
  margin: 0 auto;
  .sliderSection {
    margin: 0;
  }

  h2 {
    color: #000000;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 15px;
  }
  .meta-info {
    color: #000000;
    font-size: 14px;
  }
  video {
    max-width: 100% !important;
    max-height: 480px !important;
    margin: 20px 0;
  }
  .homeSlider {
    margin-bottom: 60px;
  }
}

.blog-ads {
  margin: 20px 0;
}



@include tablet {
  .program-listing-page, .program-details {
    padding: 0 15px;
  }
  .blog-listing.blog-listing.blog-listing {
    li {
      flex-basis: calc(33.33% - 10px);
      max-width: calc(33.33% - 10px);
      // margin: 0 5px 10px 5px;
    }
  }
}

@include allMobiles {
  .program-listing-page {
    padding: 0 5px;
  }

  .program-details {
    padding: 0 15px;
  }

  .blog-listing.blog-listing.blog-listing {
    li {
      flex-basis: calc(50% - 10px);
      max-width: calc(50% - 10px);
      // margin: 0 5px 10px 5px;
      background-color: #ebe7e7;
      border-bottom: 2px solid #979797;
      position: relative;
      .image{
        height: auto;
        min-height: 50px;
       
      }
      .info{
        height: auto;
        border: 0;
        position: static;
      }
    }
  }
  .topSection{
    flex-direction: column;
    margin-bottom: 20px;
    .rightSection{
      justify-content: flex-end;
    }
  }
}

.gotoMenu {
  display: flex;
  justify-content: space-between;
  h2 {
    margin-bottom: 10px;
    font-size: 24px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    li {
      margin: 0 5px;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: inline-block;
        padding: 5px 15px;
        color: #fff;
        background: #30609e;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
      }
    }
  }
}
.stream-listing-page{
  .provider-section{
    margin: 0;
    padding-top: 10px;
  }
}
@include tablet {
  .stream-listing-page{
    padding: 0 15px;
  }
  .stream-listing.stream-listing.stream-listing{
    li {
      flex-basis: calc(33.33% - 10px);
      max-width: calc(33.33% - 10px);
      // margin: 0 5px 10px 5px;
    }
  }
}

@include allMobiles {
  .stream-listing-page {
    padding: 0 5px;
  }

  .stream-listing.stream-listing.stream-listing {
    li {
      flex-basis: calc(50% - 10px);
      max-width: calc(50% - 10px);
      // margin: 0 5px 10px 5px;
      background-color: #ebe7e7;
      border-bottom: 2px solid #979797;
      position: relative;
      .image {
        height: auto;
        min-height: 50px;
      }
      .info {
        height: auto;
        border: 0;
        position: static;
      }
    }
  }
}


@include respond-below(xs){   
  .gotoMenu{
    h2{
      display: none;
    }
    ul{
      li{
        a{
          padding: 5px;
          font-size: 12px;
          min-width:60px;
          text-align: center;
        }
      }
    }
  }
}

