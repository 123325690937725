@import '../style/constant.scss';
@import '../style/mixins.scss';
.weeekday.weeekday.weeekday{
    // @extend .grey-gradient;
    background:#dcd7d7;
    height: 30px;
    .container{
        position: relative;
    }
    ul{
        // padding:0 4rem;
        max-width: 85%;
        margin: auto;
        li {
            
            a{
                color: black;
                padding:.4rem 1rem;
            }
        }
        .active{
            background: #61b8e3;
        }
        
    }
    .prev-link, .next-link{
        position: absolute;
        color: #000;
        top:8px;
        
        // @extend .grey-gradient;
        // background: #747474 url(../images/new/hours_bg.gif) repeat-x;
        padding: .4rem 1rem;
        margin-top: -0.5rem;
    }
    .prev-link{
        left:0;
        
    }
    .next-link{
        right:0;
    }
}
.subnav{
    padding-top: 15px;
    a{
        background: #eeeeee;
        display: inline-block;
        padding: 6px 18px;
        margin-right: 4px;
        border-radius: 4px;
        &:hover{
            //background: #8dc4e5;
            background: #62b9e4;
            text-decoration: none;
        }
        &.dm_mmt_sel{
            //background: #8dc4e5;
            background: #62b9e4;
        }
    }
}

@include respond-below(md) {
    .weeekday{
        .container{
            position: relative;
            width: 100%;
            max-width: 100%;
            overflow-y: auto;
            
        }
        .prev-link{
            left:10px;
            
        }
        .next-link{
            right:10px;
        }
         ul{
            min-width: 100%;
            padding: 0 3rem;
        }
        .scroll-nav{
            width: 100%;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none; 
            &::-webkit-scrollbar {
                display: none;
              }
              
        }
    }
  }

  @include respond-below(xs){   
    .weeekday ul {
        min-width: 1600px;
        padding: 0 6rem;
        li{
            min-width: 125px;
            text-align: center;
            margin: 0 1rem;
            display: none;
            &.active{
                display: block;
            }
        }
    }
}
// @include largeDesktop {
//     .weeekday ul li {
//         margin: 0 1.5rem;
//     }
// }