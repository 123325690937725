@import "../../style/mixins.scss";
@import "../../style/constant.scss";

.blog-listing.blog-listing.blog-listing {
  margin: 0 -5px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 1 0 calc(25% - 10px);
    max-width: calc(25% - 10px);
    margin: 0 5px 10px 5px;
    .image {
      height: 165px;
      border-radius: 0;
    }
    .info {
      height: calc(100% - 165px);
      background-color: #ebe7e7;
      padding: 10px;
      border-bottom: 2px solid #979797;
      position: relative;
      padding-bottom: 30px;
      span {
        font-size: 12px;
        color: #646464;
        display: inline-block;
        margin-bottom: 10px;
      }
      p {
        font-size: 12px;
        line-height: 14px;
        color: #000;
      }
      a {
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        left: 10px;
        bottom: 10px;
      }
    }
    h3 {
      font-size: 14px;
      color: #000;
      margin: 0;
      height: 34px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.topSection {
  display: flex;
  justify-content: space-between;
}

.genre {
  position: relative;
  select {
    -webkit-appearance: none;
    border: 0;
    padding-right: 20px;
  }
  &::before {
    content: "";
    height: 7px;
    width: 12px;
    background-image: url(../../images/downArrow.svg);
    background-size: 12px 7px;
    background-repeat: no-repeat;
    background-position: right top;
    position: absolute;
    right: 0;
    top: 5px
  }

}

.headingH2 {
  color: #000;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #979797;
  padding: 20px 0 10px 0;
  margin-bottom: 20px;
  font-weight: 900;
}

.sorting {
  margin-bottom: 20px;
  span {
    padding: 0 10px;
    border-left: 1px solid #000;
    a {
      color: #000;
    }
    &:first-child {
      padding-left: 0;
      border-left: 0;
    }
    &.active {
      font-weight: bold;
    }
  }
}

.back-btn {
  font-size: 16px;
  margin: 15px 0;
  display: inline-block;
  font-weight: bold;
}

.large-image {
  max-height: 480px;
  margin-bottom: 20px;
  overflow: hidden;
  img {
    height: 100%;
  }
}

.heading1 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
}

.blog-text {
  img{
    max-width: 100%;
  }
  h1, h2, h3, h4 {
    color: #333;
    margin: 15px 0;
    span{
      font-weight: 700; 
    }
  }
  h1 {
    font-size: 24px;
    line-height: 28px;
  }
  h2 {
    font-size: 22px;
    line-height: 26px;
  }
  h3 {
    font-size: 18px;
    line-height: 22px;
  }
  h4 {
    font-size: 16px;
    line-height: 20px;
  }
  p {
    color: #6D6D6D;
    font-size: 14px;
    line-height: 18px;
    //h2 {
    //  color: #6D6D6D;
    //  font-size: 14px;
    //  line-height: 18px;
    //  font-weight: normal;
    //  margin-bottom: 0;
    //}
    span{
      font-weight: 400;
    }
  }

}

.blog-details {
  max-width: 980px;
  margin: 0 auto;
  .sliderSection {
    margin: 0;
  }

  h2 {
    color: #000000;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 15px;
  }
  .meta-info {
    color: #000000;
    font-size: 14px;
    margin: 15px 0;
  }
  video{
    max-width: 100% !important;
    max-height: 480px !important;
    margin: 20px 0;
  }
  .homeSlider{
    margin-bottom: 60px;
  }
}

.blog-ads {
  margin: 20px 0;
}
.blog-container{
  background: #fff;
  padding: 1rem;
  margin-top: 1rem;
}

@include tablet {
  .blog-listing-page, .blog-details {
    padding: 0 15px;
  }
  .blog-listing.blog-listing.blog-listing {
    li {
      flex-basis: calc(33.33% - 10px);
      max-width: calc(33.33% - 10px);
      // margin: 0 5px 10px 5px;
    }
  }
}

@include allMobiles {
  .blog-listing-page {
    padding: 0 5px;
  }

  .blog-details {
    padding: 0 15px;
  }

  .blog-listing.blog-listing.blog-listing {
    li {
      flex-basis: calc(50% - 10px);
      max-width: calc(50% - 10px);
      // margin: 0 5px 10px 5px;
      background-color: #ebe7e7;
      border-bottom: 2px solid #979797;
      position: relative;
      .image{
        height: auto;
        min-height: 50px;
       
      }
      .info{
        height: auto;
        border: 0;
        position: static;
      }
    }
  }
}