@import './constant.scss';


@mixin hoverAnimation {
    &:after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        display: block;
        width: 0;
        height: 2px;
        background: linear-gradient(to right,#388603,#010f50,#3717aa,#be2fe2);
        background-size: 300% 100%;
        transition: width .3s ease .3s,left .3s ease .3s,
        background-position .3s ease 0s,box-shadow .3s ease;
    }
    &:hover:after{
        left: 5%;
        width: 90%;
        background-position: 100% 0;
        box-shadow: 0 4px 15px 0 rgba(255,30,41,.3);
        transition: width .3s ease 0s,left .3s ease 0s,
        background-position .3s ease .3s;
    }
}
$breakpoint: map-get($breakpoints, sm);

@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// Respond above.
@mixin respond-above($breakpoint) {

    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
  
      // Get the breakpoint value.
      $breakpoint-value: map-get($breakpoints, $breakpoint);
  
      // Write the media query.
      @media (min-width: $breakpoint-value) {
        @content;
      }
  
    // If the breakpoint doesn't exist in the map.
    } @else {
  
      // Log a warning.
      @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
  }
  

  @mixin grey-gradient{
    background: rgb(125,126,125); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(125,126,125,1) 0%, rgba(76,76,76,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(125,126,125,1) 0%,rgba(76,76,76,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(125,126,125,1) 0%,rgba(76,76,76,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d7e7d', endColorstr='#4c4c4c',GradientType=0 ); /* IE6-9 */
}



$tablet-width: 768px;
$smalldesktop-width: 980px;
$largedesktop-width: 1230px;
$mobile-width: 500px;
@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$smalldesktop-width - 1px}) {
    @content;
  }
}
@mixin allMobiles {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}


@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin largeDesktop {
  @media (min-width: #{$largedesktop-width - 1px}) {
    @content;
  }
}

@mixin smallDesktop {
  @media (min-width: #{$smalldesktop-width - 1px}) {
    @content;
  }
}
@mixin smallDesktopAndMobile {
  @media (max-width: #{$smalldesktop-width - 1px}) {
    @content;
  }
}