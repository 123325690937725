// @import '../src/style/mixins.scss';

body, *{
    // font-family: 'Lato', sans-serif;
    font-family: Arial, Helvetica, sans-serif;
}

body{
 background:#fff;
 font-size: 0.8rem;
}
.container{
    max-width: 980px;
}

#cncpt-sky1{
    margin-bottom: 30px;
}
