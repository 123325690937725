// @import '../../style/constant';
// @import '../../style/mixins';
// .footer{
//     color: #000;
//     margin-top: 1rem;
//     padding: 15px;
//     font-size: 12px;
//     background-color: #fff;
//     h2{
//         font-family:Georgia;
//         font-weight: normal;
//         font-size: 16px;
//         color: #262525;
//         margin: 0 0 10px 0;
//     }
//     .col-xs-4{
//         padding: 0 0 0 15px;
//         text-align: left;
//         width: 27%;
//         border-left:1px solid #ccc;
//         &:first-child{
//             padding: 0;
//             border:0;
//             width: 19%;
//             div{
//                 width: 50%;
//                 float: left;
//             }
//         }
//         .info-bar{
//             background: #fff;
//             .col-xs-8, .col-md-8, .col-xs-4, .col-md-4  {
//                 width: 100%;
//                 padding: 0;
//                 -ms-flex: 0 0 100%;
//                 flex: 0 0 100%;
//                 max-width: 100%;
//                 flex-direction: column;
//                 border:0;
//                 .filter {
//                     select{
//                         border: 0;
//                         font-size: 11px;
//                     }
//                 }
//                 .search{
//                     padding: 0;
//                     justify-content: flex-start;
//                    input{
//                        padding:.375rem .4rem;
//                        width: 60%;
//                    }
//                    button{
//                        border:1px solid #ccc;
//                        width: 2rem;
//                    }
//                 }
//             }
//         }
//         .channels-logo{
//             a{
//                 margin: 0 2px 5px 2px;
//                 display: inline-block;
//             }
//         }
//     }
//     nav{
//         ul{
//             margin: 0;
//             padding: 0;
//             list-style: none;
//             li{
//                 margin: 0;
//                 padding: 0;
//                 display: inline-block;
//                 border-left: 1px solid #645959;
//                 &:first-child{
//                     border: 0 ;
//                 }
//             }
//         }
//         a{
//             display: inline-block;
//             margin: 0 0.5rem;
//             color: #c4d0e6;
//             padding: 0.05rem 1rem;
//             position: relative;
//             line-height: 14px;
//             border-left: 1px solid #c4d0e6;
//             // &:hover, .active{
//             //     @include hoverAnimation
//             // }
//             &:first-child{
//                 border: 0;
//                 margin-left:0;
//             }
//         }
//     }
//     p{
//         margin: 0 0 15px 0;
//     }
//     .col-sm-4{
//         padding-right: 30px;
//     }
//     h3{
//         color:#000;
//         font-size: 11px;
//         line-height: 16px;
//         margin-bottom: 5px;
//         a{
//             color:#000;
//             display:inline-block;
//             font-family: Trebuchet MS;
//             font-weight: 400;
//         }
//     }
//     .row{
//         margin: 0;
//     }
//     .copyright{
//         padding-top: 5px;
//         border-top: 1px solid #fff;
//         text-align: center;
//         p{
//             text-align: right;
//             margin-bottom: 5px;
//         }
//     }
// }

// @include allMobiles{
//     .footer{
//         .container{
//             padding: 0;
//             .col-xs-4{
//                 width: 50%;
//                 margin-bottom: 15px;
//                 .info-bar{
//                     height: auto;
//                 }
//                 .info-bar .filter{
//                     width: 100%;
//                 }
//                 &:nth-child(3){
//                     border: 0;
//                     padding-left: 0;
//                 }
//             }
//         }
//         nav{
//             a{
//                 margin: 0;
//                 padding: 0 8px;
//             }
//         }
//         p{
//             padding-top: 10px;
//             text-align: center;
//         }
//     }

// }







@import '../../style/constant';
@import '../../style/mixins';
.footer{
    color: #fff;
    margin-top: 1rem;
    border-top: 2px solid #575757;
    padding: 15px;
    font-size: 12px;
    background-color: #1c1d1d;
    h2{
        font-size: 18px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 10px;
        color: #fff;
    }
    p{
        line-height: 1.4;
        letter-spacing: -.1px;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        color:#c4d0e6;
    }
    .col-sm-4{
        padding: 0 0;
        text-align: left;
    }
    nav{
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                margin: 0;
                padding: 0;
                display: inline-block;
                border-left: 1px solid white;
                &:first-child{
                    border: 0 ;
                }
            }
        }
        a{
            display: inline-block;
            color: #c4d0e6;
            padding: 0.6rem;
            position: relative;
            font-size: 12px;
            line-height: 0;
            height: 16px;
            border-left:0;
            background: transparent;
            // &:hover, .active{
            //     @include hoverAnimation
            // }
            &:first-child{
                border: 0;
                margin-left:0;
            }
        }
    }
    p{
        margin: 0 0 15px 0;
    }
    .col-sm-4{
        padding-right: 30px;
    }
    h3{
        color:#f2f2f2;
        font-size: 16px;
        text-transform: uppercase;
        border-bottom: 1px solid #fff;
        a{
            color:#f2f2f2;
            display:inline-block;
            line-height: 20px;
        }
    }
    .row{
        margin: 0;
    }
    .copyright{
        padding-top: 20px;
        border-top: 1px solid #fff;
        text-align: center;
    }
}

@include allMobiles{
    .footer{
        nav{
            a{
                margin: 0;
                padding: 0 8px;
            }
        }
        p{
            padding-top: 10px;
            text-align: left;
        }
    }

}
