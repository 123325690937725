
$marginbtm: 20px;
$primaryColor:#fff;
$secondaryCloar:#5d1760;
$fontColor:#222;
$borderColor:#222;
$headingColor:#000;
$fontSizeSmall: 12px;
$fontSizeMedium: 16px;
$fontSizeLarge: 18px;

$lightGreyBg: #ccc;
$smallHeading:#f90;
  
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px
);

$breakpoint: map-get($breakpoints, sm);
p{
    color: $fontColor;
}

.padding0{
    padding:0;
}
.paddingRight0{
    padding-right: 0;
}
.paddingLeft0{
    padding-left: 0;
}
.noPadding.noPadding.noPadding{
    padding: 0;
}
.grey-gradient{
    background: rgb(125,126,125); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(125,126,125,1) 0%, rgba(76,76,76,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(125,126,125,1) 0%,rgba(76,76,76,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(125,126,125,1) 0%,rgba(76,76,76,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d7e7d', endColorstr='#4c4c4c',GradientType=0 ); /* IE6-9 */
}

h1,h2,h3,h4,h5{
    color: $headingColor;
    font-weight: 900;
}

h3{
    color: $smallHeading;
    font-size: 1.5rem;
}





.notes{
    padding:2rem;
    font-size: 16px;
    background: rgba(255, 153, 90, 0.5);
    color:#000;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 800px;
    a{
        color:#3717aa;
        text-decoration: underline;
    }
}
.modal-header{
    .close{
        background: #000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    position: absolute;
    right: -15px;
    top: -15px;
    line-height: 24px;
    color: #fff;
    margin: 0;
    padding: 0;
    opacity: 1;
    }
}

.toggle-btn{
    color: #fff;
    font-size: 0.8rem;
    margin: 0 5px;
    display: inline-block;
    cursor: pointer;
    border-radius: 6px;
    background: #212121;
    padding:2px 4px;
    position: relative;
    min-height: 16px;
    &::before{
        content: "";
        width: 40%;
        background: rgba(93, 93, 93, 1);
        position: absolute;
        height: 100%;
        top:0;
        right: 0;
        border-radius: 6px;
    }
    &.off{
        background: rgba(93, 93, 93, 1);
        color: #222;
        text-align: center;
    }
    &.off::before{
        content: "";
        width: 40%;
        background: rgba(0, 0, 0, 1);
        position: absolute;
        height: 100%;
        top:0;
        left: 0;
        border-radius: 6px; 
    }

}


.print-icon{
    float:right;
    font-size: 16px;
    cursor: pointer;
}
