@import '../../style/constant';
@import '../../style/mixins';

$toggle-size: 30px;
$toggle-line-color: dimgray;
$toggle-line-focus-color: black;
$toggle-line-size: $toggle-size/8;
$toggle-line-angle: 405deg;
$toggle-anim-speed: 0.9s;
$duration: 1s;
$nav-duration: calc(var($duration) / 4);
header {
  background: #73C9ED;
  //box-shadow: 0 1px 2px 0 rgba(0,0,0,0.14);
  padding: 0;

}

.logo {
  display: block;
  padding: 0;
  border-radius: 10px;
  max-width: 400px;
  margin-bottom: 1rem;
  float: left;
  height: 60px;

  img {
    max-width: 400px;
    width: 100%;
    vertical-align: middle;
    height: 100%;
  }
}

.navbar {
  padding: 10px 0 0 0;
  justify-content: right;
}


.mobile-menu {
  .user-info {
    padding: 10px 10px;
    font-size: 17px;
    margin-top: -20px;
    color: white;
    border-bottom: 1px solid white;

    svg {
      margin-right: 10px;
      margin-top: -2px;
    }
  }

  h1 {
    color: #fff;
    margin-bottom: 20px;
    padding: 20px;
    border-bottom: 1px solid #fff;
    display: flex;
    justify-content: space-between;

    .close-btn {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 20px;
      height: 20px;
      opacity: 0.3;

      &:hover {
        opacity: 1;
      }

      &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #fff;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

  }

  .navbar {
    position: fixed;
    left: 0;
    top: 0;
    background: #222;
    width: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 9999;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    margin: 0;
    display: block;

    a {
      margin: 0;
      width: 100%;
      background-color: transparent;
      transition: color 0.3s ease-in-out;
      color: #fff;
      text-align: left;
      justify-content: left;
      font-size: 17px;
      padding: 5px 10px;
      height: auto;

      &:hover {
        transition: color 0.3s ease-in-out;
      }
    }

  }

  &.menu-expand {
    .navbar {
      width: 220px;

    }
  }
}


.btn-toggle {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: none;
  transform: translateZ(0);
  transition: transform 0.1s ease-out;

  &:active {
    transform: translateY(4px);
  }

  &:focus {
    outline: none;

    .line:after {
      background-color: $toggle-line-focus-color;
    }
  }

  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 1;
}

.line {
  display: block;
  width: $toggle-size;
  padding: $toggle-line-size/2;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: $toggle-line-size;
    background-color: $toggle-line-color;
    border-radius: 2px;
    transform: translateZ(0) rotate(0);
    transition: background-color 0.2s ease-out;
  }

  .open & {
    @for $i from 1 through 2 {
      &:nth-child(#{$i}) {
        animation: jump-#{$i} $toggle-anim-speed forwards ease;

        &:after {
          animation: line-#{$i} $toggle-anim-speed forwards ease-in-out;
        }
      }
    }
  }

  .close & {
    @for $i from 1 through 2 {
      &:nth-child(#{$i}) {
        animation: jump-#{$i} $toggle-anim-speed reverse ease;

        &:after {
          animation: line-#{$i} $toggle-anim-speed reverse ease-in-out;
        }
      }
    }
  }

  .open &,
  .close & {
    &:nth-child(3) {
      animation: jump-3 $toggle-anim-speed forwards ease-out;
    }
  }
}

@keyframes line-1 {
  10% {
    transform: translateZ(0) rotate(0)
  }
  80% {
    transform: translateZ(0) rotate($toggle-line-angle - 10)
  }
  90%, 100% {
    transform: translateZ(0) rotate($toggle-line-angle)
  }
}

@keyframes line-2 {
  10% {
    transform: translateZ(0) rotate(0)
  }
  20% {
    transform: translateZ(0) rotate(10deg)
  }
  90%, 100% {
    transform: translateZ(0) rotate(-$toggle-line-angle)
  }
}

@keyframes jump-1 {
  10% {
    transform: translateY(0)
  }
  50% {
    transform: translateY(-$toggle-line-size * 12)
  }
  90%, 100% {
    transform: translateY(-$toggle-line-size * 1)
  }
}

@keyframes jump-2 {
  10% {
    transform: translateY(0)
  }
  50% {
    transform: translateY(-$toggle-line-size * 10)
  }
  85%, 100% {
    transform: translateY(-$toggle-line-size * 3)
  }
}

@keyframes jump-3 {
  10% {
    transform: translateY(-$toggle-line-size*1) rotate(15deg)
  }
  30% {
    transform: translateY(-$toggle-line-size*4) rotate(-10deg)
  }
  50% {
    transform: translateY($toggle-line-size) rotate(5deg)
  }
  80% {
    transform: translateY(0)
  }
}

@keyframes glow {
  50% {
    box-shadow: rgba(lighten($toggle-line-color, 10%), 0.4) 0 0 2px 2px;
  }
}


@keyframes border_anim {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

nav {
  .film-selected {
    color: #cc2336;
  }

  .series-selected {
    color: #30609e;
  }

  .sport-selected {
    color: #e59449;
  }

  a {
    color: #222;
    display: inline-block;
    height: 34px;
    line-height: 34px;
    padding: 0 15px;
    text-align: center;
    position: relative;
    font-size: 13px;
    font-weight: 700;
    background-color: rgb(219, 219, 219);
    margin-left: 10px;

    &:hover {
      color: #fff;
      text-decoration: none;
      font-weight: 700;
      background-color: #222;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        border-bottom: solid 2px #fff;
        animation: border_anim 1s linear forwards;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    // @include hoverAnimation;
  }

  .active {
    background: #FFFFFF;
    color: #222;
    text-decoration: none;

    &:hover {
      color: #222;
    }
  }

  .user-link {
    position: relative;
    margin-left: 10px;

    a {
      color: #222;
      background: #dbdbdb;
      box-shadow: none;
      padding: 7px 0.5rem;
      display: flex;
      align-items: center;

      svg {
        margin: -4px 10px 0 0;
      }

      &:hover {
        color: #fff;
        text-decoration: none;
        background: #2e2e2e;
      }

      span {
        height: 18px;
        display: inline-block;
        line-height: 18px;
      }
    }

    .dropdown {
      display: none;
      position: absolute;
      top: 37px;
      z-index: 9;

      a {
        color: #222;
        display: inline-block;
        padding: 5px .7rem;
        border-radius: 4px;
        position: relative;
        text-transform: uppercase;
        box-shadow: 0 2px 4px 4px #ccc;
        background: #fff;
      }

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #fff;
        position: absolute;
        top: -7px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 10;
      }
    }

    &:hover {
      .dropdown {
        display: block;

        a {
          color: #222;
          background: #fff;
        }
      }

    }
  }
}

.login-form {
  width: 200px;
  float: right;
  padding-top: 5px;

  .form-group {
    margin-bottom: 1px;

    .form-control {
      height: 1.1rem;
      padding: 3px;
      font-size: 10px;
    }
  }

  .btn-primary {
    height: 1.4rem;
    float: right;
    font-size: 10px;
    padding: 0 5px;
    line-height: 10px;
    background: #fff;
    color: #000;
    border-radius: 0;
  }

  .form-check {
    float: left;
  }
}

.search {
  position: relative;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 4px 0;

  input {
    height: 1.4rem;
    border-radius: 0;
    font-size: 0.8rem;
  }

  .btn {
    height: 1.4rem;
    padding: 0;
    width: 3rem;
    font-size: 0.8rem;
    background: #fff;
    border-radius: 0;
    margin-left: 5px;
    color: #000;
  }
}


@include smallDesktop {
  .mobile-menu,
  .btn-toggle {
    display: none;
  }

}

@include respond-above(md) {
  .logo {
    margin-bottom: 0;
  }
}

@include respond-below(md) {
  .container {
    max-width: 100%;
  }
  header {
    .dropdown.dropdown.dropdown {
      right: 0;
      top: 35px;

      &::before {
        left: auto;
        right: 10px;
      }
    }
  }
  .destopNav {
    display: none;
  }
}

@include respond-below(sm) {
  .logo {
    margin: 0;
    max-width: 300px;
  }

  .search {
    margin: 0;

    width: 100%;
    display: block;
    padding: 0 5px;
    padding-top: 10px;

    input {
      width: 70%;
    }
  }
  header {
    position: relative;
  }
  .login-form {
    width: 170px;
    position: absolute;
    right: 5px;
    top: -67px;

    label {
      font-size: 10px;
    }
  }
  .col-xs-8 {
    padding: 0 5px;
  }
  .filter {
    margin-right: 5px;
    padding-top: 5px;
  }
}

@include respond-below(xs) {
  header {
    .col-xs-12.col-sm-12.col-md-12.col-lg-6 {
      position: static;
    }
  }
}