@import '../style/constant.scss';
@import '../style/mixins.scss';

.modal-header {
  border: 0;
  padding: 2rem
}

.modal-body {
  padding: 0 2rem 2rem 2rem
}

.form-control {
  //height: 24px;
  //border-radius: 0;
}

.form-group {
  margin-bottom: 1rem;

  label {
    font-size: 12px;
    font-weight: 700;
  }
}

.modal-header .close {
  margin: -2rem -2rem -1rem auto;
}

.modal-footer {
  border: 0;
  padding: 0 2rem 2rem 2rem
}

.form-control:focus {
  //color: #495057;
  //background-color: #fff;
  //border-color: $borderColor;
  //outline: 0;
  //box-shadow: none;
}

.btn-primary {
  background-color: $borderColor;
  border: 0;
  box-shadow: none;
}

.btn {
  line-height: 1rem;
  border-radius: 0;
  background-color: #495057;
}

.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  border-color: $secondaryCloar;
  background-color: #4e6c7d;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

.modal-footer {
  .btn-primary {
    margin: 0 auto;
    min-width: 120px;
    line-height: 2;
    border-radius: 2rem;
    text-transform: uppercase;
  }
}

.form {
  .row {
    margin-bottom: 1rem;
  }

  input[type="button"] {
    color: #fff;
  }
}


.form span.error {
  color: red;
  font-size: 13px;
}

.form {
  .row {
    .form-group {
      margin-right: 15px;
    }
  }
}

.register-form {
  p {
    font-size: 12px;
    font-weight: normal;
  }

  h2 {
    margin-bottom: 0.75rem;
  }

  h3, h4 {
    color: #000;
    font-size: 15px;
  }

  ul {
    margin: 0;
    padding-left: 10px;

    li {
      margin-bottom: 20px;
      font-size: 12px;

      h2 {
        margin-bottom: 20px;
      }
    }

  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .btn-primary {
    margin: 15px auto;
    min-width: 100px;
    padding: .5rem 2rem;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
  }

  .btn-default {
    border-radius: 5px;
    background-color: #cccccc;
    color: #333333;
  }

  .row {
    flex-direction: column;
    margin-bottom: 10px;
  }

  label {
    margin-bottom: 2px;
    font-weight: bold;
  }

  .form-control {
    font-family: Tahoma;
    font-weight: normal;
    color: #000000;
    font-size: 15px;
  }
  .filter {
    width: 100%;
  }
  .card-body {
    padding: 10px 0;
  }
}