@import '../style/constant.scss';
@import '../style/mixins.scss';

body {
  background-color: #9cd8fa;
}

.margin0.margin0.margin0.margin0 {
  margin: 0;
}

.dblock.dblock.dblock.dblock {
  display: block;
}

.my-list {
  min-height: 300px;
  padding: 1.2rem 0 0 0;
  border-top: 9px solid red;

  h2 {
    font-size: 14px;
    color: #000;
    padding-bottom: 10px;
    margin-bottom: 1rem;
    padding: 0.5rem 0;

    .print-icon {
      a {
        color: #222;
      }
    }
  }

  .text {
    color: #333;
    display: block;
    font-size: 10px;
    padding: 0 15px;
  }

  ul {
    padding: 0 5px 15px 0;
    margin: 0;

    li {
      list-style: none;
      color: #fff;
      font-size: 11px;
      margin-bottom: 10px;
      cursor: pointer;

      h3 {
        font-size: 15px;
        float: none;
        color: #cc2336;
      }

      .flex {
        display: flex;

        > div {
          max-width: calc(100% - 30px);
          position: relative;

          p {
            margin: 0;
          }
        }
      }

      .smalllogo {
        float: left;
        margin-right: 5px;
        width: 30px;

        img {
          max-width: 100%;

        }
      }

      .remove-icon {

        display: inline-block;
        background: #fff;
        height: 16px;
        width: 16px;
        border-radius: 10px;
        cursor: pointer;
        position: absolute;
        right: -4px;
        top: 0;

        svg {
          float: right;
          color: #c32626;
          font-size: 14px;
          margin-top: 1px;
          margin-right: 1px;
        }
      }

      .name {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
      }

    }
  }

}

.forgot-password {
  .footer {
    margin-top: 25%;
  }
}

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1021;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;

  .modal-inner-container {
    background: rgba(256, 256, 256, 1);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.30);
    border-radius: 6px;
    position: relative;
    height: 100%;
    overflow-y: auto;
    max-height: 80%;
    width: 500px;
    margin: 0 auto;
    margin-top: 8vh;
    overflow-x: hidden;
  }

  .modal-footer.modal-footer {
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.5);
    padding: 1rem 20px;
    margin-top: 10px;
    border-radius: 0;
    position: absolute;
    bottom: 0;
    border-radius: 0 0 6px 6px;
    width: 100%;
    background: #fff;
  }

  .close-modal {
    position: absolute;

  }
}

.switch-layout {
  font-size: 0.8rem;
  float: right;
  display: flex;
  align-items: center;
  margin: 5px 0;

  span {
    cursor: pointer;
  }

  .toggle-btn {
    min-width: 30px;
    min-height: 12px;

  }
}

.add-container {
  #cncpt-lb1 {
    max-width: 100%;

  }

  iframe {
    max-width: 100%;
  }
}

.filter {
  label {
    margin-right: 10px;
  }

  select {
    border: 1px #dcdfe6 solid;
    // background-color: #4c9fc1;
    margin: 2px;
    width: 150px;
    height: 18px;
  }
}

.btn {
  color: #fff;
  margin: 0 0.4rem;
  text-transform: capitalize;
  font-size: 12px;
}

.search-result {
  // margin-top: -40px;
  h2 {
    margin: 15px 0;
  }

  .heading {
    margin: 15px 0;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
  }

  .action-bar {
    margin-bottom: 20px;

    .heading {
      line-height: 23px;
      background: url('../images/new/menu_bg_unselected.gif');
      font-weight: bold;
      font-size: 11px;
      border-radius: 4px 4px 0 0;
      display: block;
      color: #fff;
      padding: 0 20px;
      margin: 0;
    }

    .filters {
      border: 1px solid #c6c6c6;
      border-radius: 0 0 4px 4px;
      padding: 5px 20px;

      .label {
        display: inline-block;
        margin-right: 20px;
      }

      select {
        height: 25px;
        margin-right: 20px;
      }
    }
  }

  .search-list {
    list-style: none;
    margin: 0;
    padding: 30px 0 0 0;

    li {
      list-style: none;
      margin-bottom: 15px;
      background-color: #f4f4f4;
      padding: 10px;
      p{
        font-size: 13px;
        line-height: 18px;
      }
      > div{
        span{
          font-size: 13px;
          line-height: 18px;
        }
       
      }
      &.inactive {
        opacity: .5;
      }

      .flex {
        display: flex;
        justify-content: flex-start;
        position: relative;
        > div{
          font-size: 13px;
          line-height: 18px;
          div{
            font-size: 13px;
          line-height: 18px;
          }
          strong{
            font-size: 18px;
            line-height: 24px;
          }
        }
        .action-list {
          margin-left: auto;
          position: absolute;
          right: 0;
          top:-10px;
          li {
            margin-bottom: 0;
            padding: 2px;

            span {
              font-size: 16px;
            }

            &:first-child {
              span {
                color: red;
              }
            }
          }
        }
      }

      .logo {
        background: none;
        margin: 0 15px 0 0;
        float: none;
        height: auto;
      }

      &.selected {
        webkit-box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.50);
        -moz-box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.50);
        box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.50);
        background-color: #e9f6fe;
      }
    }
  }

  .not-found-message {
    font-weight: normal;
    font-size: 14px;

    svg {
      font-size: 14px;
      margin-right: 10px;
      margin-bottom: 2px;
    }
  }
}

.print-btn{
  float: right;
  font-size: 16px;
  margin-top: 5px;
  cursor: pointer;
}

.anchor-link {
  color: #00a0dc;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.myProgramList-modal.myProgramList-modal.myProgramList-modal {
  min-height: 300px;
  height: 400px;
  overflow: hidden;

  .close {
    background: transparent;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    position: absolute;
    right: 10px;
    top: 5px;
    line-height: 24px;
    color: #fff;
    margin: 0;
    padding: 0;
    opacity: 1;
    font-size: 15px;
  }

  .my-list {
    height: 100%;
    overflow-y: auto;
    padding: 10px 20px;

    .print-icon {
      margin-right: 20px;
    }
  }

}

.sponsorBanner {
  position: relative;
  background-color: #E8ECF1;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 40px;
  max-width: 1024px;

  .title {
    color: #5D6164;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #D1D3D6;
    padding: 4px;
    position: absolute;
    left: 0;
    top: -10px;
    font-weight: bold;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    li {
      display: inline-block;
      padding: 10px 0;
      margin: 0 10px;
      flex: 1 1 33%;
      max-width: 33%;

      img {
        max-width: 100%;
      }
    }
  }
}

@include allMobiles {
  body {
    background-color: white;
  }
  .sponsorBanner {
    padding: 20px 10px;

    ul {
      display: block;

      li {
        max-width: calc(100% - 20px);
        margin: 0;

        img {
          max-width: 100%;
        }
      }
    }
  }
  .print-btn{
    margin-top: -35px;
  }
  .timeline-layout{
    .paddingRight0{
      padding: 0 !important;
    }
  }
}

.my-list.my-list.my-list {
  .stream-list.stream-list.stream-list {
    li {
      display: flex;
      justify-content: flex-start;
      padding: 10px;
      align-items: center;
      position: relative;

      &:nth-child(even) {
        background-color: #e4e4e4;
      }

      .image {
        max-width: 120px;
        margin-right: 15px;

        img {
          max-width: 100%;
        }
      }

      h3 {
        font-size: 16px;
        color: #222;
      }

      .close-btn {
        position: absolute;
        right: 13px;
        color: #222;
        font-weight: 500;
        font-size: 20px;
        padding: 3px;
        cursor: pointer;
        top: 3px;
      }
    }
  }
}

.show-mylist {
  display: none;

  .showlist {
    border: 0;
    background: #f5a600;
    margin: 5px 0;
    border-radius: 4px;
    padding: 3px 5px;
  }
}

.channel-list-info {
  margin: 0 auto;
  margin-top: 40px;
  background: #2e2e2e;
  color: #fff;
  max-width: 780px;
  padding: 10px;
  border-radius: 10px;

  p {
    color: #fff;
    float: left;
    width: calc(100% - 60px);
    margin-bottom: 0;
  }

  .icon {
    background-image: url('../images/new/info_bg.gif');
    height: 40px;
    width: 40px;
    float: left;
    background-position: -13px -8px;
    border-radius: 50%;
    margin-right: 20px;
  }
}

.welcome-message {
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;

  .text {
    float: left;
    width: calc(100% - 120px);

    .close-btn {
      position: absolute;
      right: 13px;
      color: #222;
      font-weight: 500;
      font-size: 20px;
      padding: 3px;
      cursor: pointer;
      top: 3px;
    }
  }

  h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  i {
    display: block;
    float: left;
    margin-right: 40px;
    margin-top: 20px;

  }
}

.sidebar {
  padding-top: 15px;
  border-left: 1px solid #eeeeee;
  padding-left: 10px;

  .my-list {
    padding: 0;

    .text{
      padding: 0;
    }
  }
}


.sidebar-nav {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      // line-height: 55px;
      // height: 55px;
      // width: 110px;
      border-bottom: solid 1px #bfbfbf;
      padding:5px 3px;
      a {
        padding: 0;
        color: #575757;
        background: transparent;
        text-align: left;

        &:hover {
          color: #1e9cbc;
        }

        &.active {
          font-weight: bold;
          color: #1e9cbc;
        }
      }
    }
  }
}

.static-content {
  h1 {
    font-family: Georgia;
    font-weight: normal;
    // font-size: 18px;
    // color: #000000;
    margin: 0;
    color: #f90;
    font-size: 18px;
    font-weight: 700;
  }
  a{
    color: #007bff;
    line-height: 14px;
    display: inline-block;
    text-decoration: underline;
  }
h2{
  font-size: 15px;
}
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
    }
  }
}


.rerun-modal {
  h5 {
    font-size: 18px;
  }

  .text {
    font-size: 15px;
    margin-left: 20px;
  }
}

.top-add-container.sticky {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

a.admin-link {
  color: #98ad43;
}

@include respond-below(xs) {
  .modal-overlay {
    .modal-inner-container {
      max-width: 100%;
    }
  }
  .show-mylist {
    display: block;
  }
  .switch-layout {
    display: none;
  }
  .sidebar {
    display: none;
  }
  .info-bar {
    .col-xs-6 {
      width: 50%;
    }

    .filter {
      label {
        margin: 0;
      }

      width: 33%;
    }

    .category-drop-down {
      margin: 0 0 5px 0;
      width: 100%;
    }
  }
  .main-content {
    .container {
      padding: 0 1rem;
    }
  }
}

@include smallDesktop {
  .timeline-layout {
    .padding0 {
      max-width: 191px;
    }

    .paddingLeft0 {
      width: calc(100% - 191px);
      max-width: calc(100% - 191px);
      padding-right: 10px;
      flex: 0 0 85%;
    }
  }


}

user-container {
  max-width: 800px;
  margin: 0 auto;

  .my-list {
    background: none;
    border: 1px solid #ccc;

    ul li .name {
      color: #000;
    }

    h2 {
      color: #000;
      border-bottom: 1px solid #000;

      .print-icon {
        a {
          color: #000;
        }

      }
    }
  }
}

.user-container {
  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .my-list {
    margin: 0 0 2rem;
    border-radius: 10px;
    min-height: 300px;
    background: none;
    padding: 0;
    border: 1px solid #ccc;

    h2 {
      font-size: 1rem;
      margin: 0;
      padding: 10px 15px;
    }

    p {
      padding: 10px 15px;
    }

    ul {
      li {
        padding: 10px 15px;

        .flex {
          display: flex;
          flex-wrap: wrap;
          max-width: 100%;
          justify-content: space-between;

          div {
            flex-grow: 1;

            p {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@include smallDesktopAndMobile {

  .user-container {
    padding: 0;
  }
  .user-container .my-list {
    max-width: 100%;
    // margin: 0 auto;
  }
  .col-md-10.noPadding.noPadding.noPadding {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
  }
}

@include tablet {
  .timeline-layout {
    .padding0 {
      max-width: 191px;
    }

    .paddingLeft0 {
      width: calc(100% - 191px);
      max-width: calc(100% - 191px);
      padding-right: 10px;
      flex: 0 0 85%;
    }
  }
}

.main-content {
  .sticky-top.sticky-top {
    z-index: 998 !important;
    padding: 0;
    min-height: 30px;
    margin-bottom: 15px;

    .navbar {
      background-color: transparent;

      a {
        margin: 0
      }
    }
  }
}


.no-height.no-height.no-height {
  height: 0;
}
.CookieConsent {
  div {
    color: white;
  }
  a {
    color: #8cb1ef;
  }
}
@include allMobiles {
  .myProgramList-modal.myProgramList-modal.myProgramList-modal {
    width: 90%;

    .my-list {
      border-radius: 0;
    }
  }
  .info-bar {
    padding: 10px;
  }
  .show-mylist {
    display: block;
    text-align: right;
    position: absolute;
    width: 195px;
    right: 0;
    top: 5px;

    .showlist {
      margin: 5px;
    }
  }
  .switch-layout {
    display: none;
  }
  .sidebar {
    display: none;
  }
  .static-page {
    h3 {
      font-size: 1.2rem;
    }
  }
  .user-container .my-list {
    max-width: 100%;
  }
  .timeline-layout {
    padding: 0;

    .paddingLeft0 {
      padding: 0;
    }
  }
  .program-list {
    margin: 0;
    justify-content: center;

    .item {
      width: calc(50% - 6px);
      max-width: calc(50% - 6px);
    }
  }
  h2 {
    font-size: 22px;
  }
  h4 {
    font-size: 18px;
  }
  .setting-page {
    .col-6 {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .col-md-10.noPadding.noPadding.noPadding {
    padding: 0;
  }
  .search-result {
    .action-bar {
      .filters {
        padding: 5px 10px;

        .label,
        select {
          margin-right: 10px;
        }

        select {
          width: 125px
        }
      }

      .heading {
        padding: 0 10px
      }
    }

    .search-list {
      li {
        position: relative;

        .logo {
          margin-right: 5px;
          padding-top: 20px;
        }
      }

      .action-list {
        width: 152px;
        position: absolute;
        right: 0;
        top: -4px;
      }
    }
  }

  .subnav {
    margin:10px 0;
    padding: 0 20px;

    a {
      padding: 5px;
      margin-bottom: 5px;
      display: inline-block;
      width: calc(50% - 10px);
    }

  }
  .welcome-message {
    padding: 20px 20px 5px 10px;

    i {
      margin-right: 20px;
    }

    .text {
      width: calc(100% - 90px);

      p {
        margin-bottom: 10px;
      }
    }
  }
  .sidebar-nav {
    display: none;
  }
  .main-content {
    &.container {
      padding-bottom: 20px;
    }
  }
  // .top-add-container {
  //   min-height: 480px;
  //   max-height: 580px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   #cncpt-mob1 {
  //     position: sticky;
  //     top: 0;
  //   }
  // }
  .top-add-container {
    height: 480px;
    min-height: 570px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    .ad-placeholder{
      min-height: 570px;
      // background: #e8ecf1;
      width: 320px;
      min-width: 320px;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      > div,
      #cncpt-mob1 {
        position: sticky;
        top: 66px !important;
        margin: 0 !important;
        width: 100%;
      }
    }
    
  }
  // .top-add-container{

  //     transition: all 0.35s; display: block; position: fixed; top:0; display: flex; justify-content: center; align-items: center; height: 480px;  top: 0;
  //     left: 0;
  //     background: #fff;
  //     width: 100%;
  //     z-index: 999;    }

  //   `
  .grid-add-container-outer {
    // height: 480px;
    background: rgb(190, 189, 189);
    width: 100%;
    position: relative;
  }
  .grid-add-container {
    //   height: 480px;
    //   transition: all .5s ease-in;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #fff;
    width: 100%;
    position: relative;
    //   border:1px solid red;
  }
  .grid-add-container-height {
    height: 320px;
  }
  .mob-1st-ads {
    margin: 0 0 20px 0;
  }

  .search-result {
    .search-list {
      padding: 0;
    }
  }


}


.filter {
  width: 30%;
  margin-right: 1%;
  cursor: pointer;

  > div {
    height: 30px;

    .css-13cymwt-control,
    > div {
      min-height: 30px;

      .css-1xc3v61-indicatorContainer,
      .css-15lsz6c-indicatorContainer {
        padding: 4px 8px;
      }
    }
  }
}
#adscontainer{
  width: 100%;
}
.ad-label{
  color: #666;
  font-size: 14px;
  position: relative;
  padding: 0 0px;
  width: 100%;
  display: block;
  text-align: center;
  align-items: center;
  margin-bottom: 5px;
  min-width: 120px;
  &:before {
    content: '';
    height: 1px;
    width: 100%;
    display: block;
    position: absolute;
    background: #ccc;
    top:10px;
  }
  span{
    padding: 0 10px;
    background: #fff;
    position: relative;
    z-index: 1;
  }
}

.pagination {
  justify-content: center;
  margin-top: 20px;

  li {
    a {
      color: #fff;
      text-align: center;
      margin: 0 3px;
      height: 24px;
      min-width: 24px;
      line-height: 24px;
      padding: 0 2px;
      display: inline-block;
      background-color: #747474;
    }

    &.active {
      a {
        background: rgb(32, 124, 202); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(32, 124, 202, 1) 0%, rgba(32, 124, 202, 1) 12%, rgba(32, 124, 202, 1) 12%, rgba(32, 124, 202, 1) 20%, rgba(41, 137, 216, 1) 44%, rgba(30, 87, 153, 1) 91%, rgba(30, 87, 153, 1) 91%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(32, 124, 202, 1) 0%, rgba(32, 124, 202, 1) 12%, rgba(32, 124, 202, 1) 12%, rgba(32, 124, 202, 1) 20%, rgba(41, 137, 216, 1) 44%, rgba(30, 87, 153, 1) 91%, rgba(30, 87, 153, 1) 91%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(32, 124, 202, 1) 0%, rgba(32, 124, 202, 1) 12%, rgba(32, 124, 202, 1) 12%, rgba(32, 124, 202, 1) 20%, rgba(41, 137, 216, 1) 44%, rgba(30, 87, 153, 1) 91%, rgba(30, 87, 153, 1) 91%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#207cca', endColorstr='#1e5799', GradientType=0);

      }

    }

    &.previous, &.next {
      a {
        width: 70px;
        padding: 0 5px;
      }

    }
  }
}

.sliderSection {
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
  background: #fff;

  h2 {
    font-size: 20px;
    color: #575757;
    margin-bottom: 10px;
  }
}

.homeSlider {
  position: relative;
  margin: 0 -5px 40px -5px;
  // margin-bottom: 20px;
  .item {
    height: 170px;
    width: 240px;
    cursor: pointer;
  }
  .blogImage{
      height: 100%;
      width: 100%;
      background-position: 50%;
      background-size: cover;
      border-radius: 10px;
  }
}

.play-icon {
  background: url("../images/ic_play.svg") 0 0 no-repeat;
  height: 28px;
  width: 28px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.addTOlist-icon {
  background: url("../images/ic_addToList.svg") 0 0 no-repeat;
  height: 28px;
  width: 28px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.addTOlist-icon2 {
  background: url("../images/ic_add-to-list-icon2.svg") 0 0 no-repeat;
  height: 35px;
  width: 35px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.like-icon {
  background: url("../images/ic_thumb-up-unchecked.svg") 0 0 no-repeat;
  height: 35px;
  width: 35px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.dislike-icon {
  background: url("../images/ic_thumb-down-unchecked.svg") 0 0 no-repeat;
  height: 35px;
  width: 35px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.heart-icon {
  background: url("../images/ic_heart.svg") 0 0 no-repeat;
  height: 35px;
  width: 35px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  fill: #fff;
}

.like-icon, .dislike-icon, .addTOlist-icon2 {
  opacity: 0.5;

}


.like-icon.liked, .dislike-icon.disliked, .addTOlist-icon2.watched {
  opacity: 1;

}

.HOTSTAR_icon, .hotstar_icon {
  background: url("../images/provider/disneyplus.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
}

.NETFLIX_icon, .netflix_icon {
  background: url("../images/provider/netflix.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.PRIME_VIDEO_icon, .primevideo_icon {
  background: url("../images/provider/AmazonPrime.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.VIA_PLAY_icon, .viaplay_icon {
  background: url("../images/provider/vaiplay.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.C_MORE_icon, .cmore_icon {
  background: url("../images/provider/cMore.svg") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.HBO_NORDIC_icon,
.hbo_nordic,
.hbo_icon {
  background: url("../images/provider/hbomax.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.DISCOVERY_PLUS_icon, .dplay_icon {
  background: url("../images/provider/discoveryplus.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.HBO_MAX_icon,
.hbomax_icon {
  background: url("../images/provider/hbomax.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.APPLE_TV_icon,
.appletv_icon {
  background: url("../images/provider/appletv.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.viafree_icon {
  background: url("../images/provider/viafree.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.plejmo_icon {
  background: url("../images/plejmo-icon.svg") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.tv4play_icon {
  background: url("../images/fyran-logo.svg") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.svtplay_icon {
  background: url("../images/provider/svt.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.sfanytime_icon {
  background: url("../images/provider/sftime.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.streamly_icon {
  background: url("../images/streamly-text-logo.svg") 0 0 no-repeat;
  height: 70px;
  width: 70px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.player-container {
  position: relative;
  padding-top: 56.25%;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

@include smallDesktopAndMobile {
  .sliderSection {
    margin: 0;
  }
  .main-content {
    .container {
      max-width: 100%;
    }
  }
  .homeSlider {
    margin: 0 0 50px 0;
  }

}

@include respond-below(xs) {
  .stream-play {
    .modal-header {
      padding: 0;
    }

    .modal-body {
      padding: 0;
    }
  }
  .close {
    z-index: 9;
  }
}


body {
  margin: 0;
  padding: 0;
}

div {
  text-align: left;
}

img {
  border: 0;
}

#ma {
  margin: 0 auto 80px auto;
  position: relative;
  width: 980px;
}

/* Header */
#he {
  position: relative;
  width: 795px;
  height: 133px;
  background: white;
}

/* Header Top */
#he1 {
  position: relative;
  width: 795px;
  height: 75px;
}

#he11 {
  position: relative;
  width: 795px;
  height: 75px;
}

#he12 {
  position: absolute;
  width: 65px;
  height: 65px;
  top: 7px;
  left: 425px;
}

/* Header Top Login */
#he13 {
  position: absolute;
  width: 182px;
  height: 75px;
  top: 0;
  left: 800px;
}

#he14 {
  position: absolute;
  width: 71px;
  height: 75px;
  top: 0px;
  left: 724px;
}

#he131 {
  position: absolute;
  width: 162px;
  height: 14px;
  top: 8px;
  left: 10px;
  font-family: Trebuchet MS;
  font-weight: bold;
  color: #ffffff;
  font-size: 9px;
}

#he132 {
  position: absolute;
  width: 162px;
  height: 14px;
  top: 22px;
  left: 10px;
}

#he133 {
  position: absolute;
  width: 162px;
  height: 75px;
  top: 38px;
  left: 10px;
}

#he134 {
  position: absolute;
  width: 162px;
  height: 14px;
  top: 52px;
  left: 10px;
  font-family: Trebuchet MS;
  font-weight: bold;
  color: #ffffff;
  font-size: 9px;
}

#he135 {
  position: absolute;
  width: 162px;
  height: 14px;
  top: 55px;
  left: 126px;
}

#he136 {
  position: absolute;
  width: 162px;
  height: 14px;
  top: 8px;
  left: 10px;
  font-family: Trebuchet MS;
  font-weight: bold;
  color: #ffffff;
  font-size: 9px;
}

#he137 {
  position: absolute;
  width: 50px;
  height: 14px;
  top: 8px;
  left: 125px;
}

#he138 {
  position: absolute;
  width: 162px;
  height: 75px;
  top: 28px;
  left: 15px;
}

/* Header Menu */
#he2 {
  position: relative;
  width: 980px;
  height: 34px;
}

/* Header Submenu */
#he3 {
  position: relative;
  width: 980px;
  height: 24px;
  // background-image: url(/images/v3/submenu_bg.gif);
}

#he31 {
  position: absolute;
  width: 140px;
  height: 17px;
  top: 4px;
  left: 11px;
}

#he32 {
  position: absolute;
  width: 140px;
  height: 17px;
  top: 4px;
  left: 174px;
}

#he33 {
  position: absolute;
  width: 85px;
  height: 17px;
  top: 4px;
  left: 339px;
}

#he34 {
  position: absolute;
  width: 140px;
  height: 17px;
  top: 4px;
  left: 735px;
}

/* Content */
#co {
  position: relative;
  width: 980px;
  margin-bottom: 10px;
  background: white;
}

/* Footer */
#fo {
  position: relative;
  width: 980px;
  height: 190px;
  background: white;
  margin-bottom: 10px;
}

#fo1 {
  position: absolute;
  width: 400px;
  height: 14px;
  top: 7px;
  left: 10px;
}

#fo2 {
  position: absolute;
  width: 775px;
  height: 1px;
  top: 29px;
  left: 10px;
}

#fo3 {
  position: absolute;
  width: 180px;
  height: 140px;
  top: 39px;
  left: 10px;
}

#fo31 {
  position: absolute;
  width: 90px;
  height: 120px;
  top: 25px;
  left: 0;
  line-height: 20px;
}

#fo32 {
  position: absolute;
  width: 90px;
  height: 120px;
  top: 25px;
  left: 90px;
  line-height: 20px;
}

#fo33 {
  position: absolute;
  width: 1px;
  height: 120px;
  top: 0px;
  left: 179px;
}

#fo4 {
  position: absolute;
  width: 180px;
  height: 140px;
  top: 39px;
  left: 210px;
}

#fo41 {
  position: absolute;
  width: 180px;
  top: 30px;
  left: 0px;
}

#fo411 {
  position: relative;
  width: 180px;
  margin-bottom: 3px;
}

#fo412 {
  position: relative;
  width: 180px;
  margin-bottom: 3px;
}

#fo413 {
  position: relative;
  width: 180px;
  margin-bottom: 3px;
}

#fo414 {
  position: relative;
  width: 180px;
  margin-bottom: 3px;
}

#fo43 {
  position: absolute;
  width: 1px;
  height: 120px;
  top: 0px;
  left: 210px;
}

#fo5 {
  position: absolute;
  width: 250px;
  height: 140px;
  top: 39px;
  left: 453px;
}

#fo51 {
  position: absolute;
  width: 250px;
  top: 30px;
  left: 0px;
}

#fo52 {
  position: absolute;
  width: 1px;
  height: 120px;
  top: 0px;
  left: 260px;
}

#fo6 {
  position: absolute;
  width: 250px;
  height: 140px;
  top: 39px;
  left: 750px;
}

#fo61 {
  position: absolute;
  width: 250px;
  height: 120px;
  top: 25px;
  left: 0px;
  line-height: 20px;
}

#fo7 {
  position: absolute;
  width: 400px;
  height: 14px;
  top: 165px;
  left: 644px;
}

#bt {
  position: relative;
  width: 795px;
  height: 120px;
  margin-bottom: 10px;
}

#br {
  position: absolute;
  width: 185px;
  height: 360px;
  top: 310px;
  left: 815px;
  margin-bottom: 10px;
}

/* leta.se annons start */
#letase {
  position: relative;
}

#letase_holder {
  position: relative;
  margin-top: -20px;
}

#letasefulhack {
  position: absolute;
  top: 0px;
  left: 0px;
}

/* leta.se annons slut */
/* Inputs */
input, select, textarea {
  font-family: Tahoma;
  font-size: 9px;
}

.submenudrop1 {
  height: 15px;
  width: 140px;
  font-family: Tahoma;
  font-size: 9px;
}

.submenudrop2 {
  height: 15px;
  width: 85px;
  font-family: Tahoma;
  font-size: 9px;
}

/* Text */
h1 {
  font-family: Georgia;
  font-weight: normal;
  font-size: 18px;
  color: #000000;
  margin: 0;
}

h2 {
  font-family: Georgia;
  font-weight: normal;
  font-size: 16px;
  color: #262525;
  margin: 0;
}

h3 {
  font-family: Georgia;
  font-weight: normal;
  font-size: 16px;
  color: #262525;
  margin: 0;
}

p {
  font-family: Trebuchet MS;
  font-weight: bold;
  font-size: 11px;
  color: #000000;
  margin: 5px 0 5px 0;
}

/* Navigation */
a.folink {
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

/* Day select */
.dm {
  position: relative;
  width: 640px;
  height: 40px;
}

.dm_s {
  width: 23px;
  height: 20px;
  text-align: center;
  background: #dcd7d7;
  vertical-align: middle;
}

a.dm_smt {
  font-family: Tahoma;
  font-size: 8px;
  font-weight: bold;
  color: #000000;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}


.dm_l {
  width: 119px;
  height: 20px;
  text-align: center;
  background: #dcd7d7;
  vertical-align: middle;
}

.dm_lsel {
  width: 119px;
  height: 20px;
  text-align: center;
  background: #61b8e3;
  vertical-align: middle;
}

a.dm_lmt {
  font-family: Trebuchet MS;
  font-size: 11px;
  font-weight: bold;
  color: #000000;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

.dm_lmt_sel {
  font-family: Trebuchet MS;
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}

.dm_m {
  height: 20px;
  text-align: center;
  background: #eeeeee;
  vertical-align: middle;
}

.dm_msel {
  height: 20px;
  text-align: center;
  background: #8dc4e5;
  vertical-align: middle;
}

a.dm_mmt {
  font-family: Trebuchet MS;
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}


a.dm_mmt_sel {
  font-family: Trebuchet MS;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  background: #8dc4e5;
  &:hover{
    text-decoration: underline;
  }
}



.dm_g {
  width: 288px;
  height: 20px;
  text-align: center;
  // background-image: url(/images/v3/day_gradient.gif);
}

/* TvBoxes */
.tvb {
  position: relative;
  width: 310px;
}

.tvb_chartname {
  height: 26px;
  margin-left: 15px;
  vertical-align: middle;
  font-family: Trebuchet MS;
  font-size: 12px;
  font-weight: bold;
  color: #000000;
}

.tvb_chart {
  position: relative;
  width: 310px;
  margin: 10px 0 10px 0;
}

.tvb_event_active {
  position: relative;
  height: 15px;
  width: 310px;
  background-color: #61b8e3;
}

.tvb_event_activesub {
  position: relative;
  height: 15px;
  width: 310px;
  background-color: #ffffff;
}

.tvb_event_activesub_date {
  position: absolute;
  top: 0;
  left: 15px;
  width: 35px;
  color: #adadad;
  font-family: Trebuchet MS;
  font-size: 11px;
}

.tvb_event_activesub_name {
  position: absolute;
  top: 0;
  left: 50px;
  width: 295px;
  color: #adadad;
  font-family: Trebuchet MS;
  font-size: 11px;
}

.tvb_event_active {
  position: relative;
  height: 15px;
  width: 3100px;
  background-color: #61b8e3;
}

.tvb_event_active_date {
  position: absolute;
  top: 0;
  left: 15px;
  width: 35px;
  color: #ffffff;
  font-family: Trebuchet MS;
  font-size: 11px;
  font-weight: bold;
}

.tvb_event_active_name {
  position: absolute;
  top: 0;
  left: 50px;
  width: 235px;
  color: #ffffff;
  font-family: Trebuchet MS;
  font-size: 11px;
  font-weight: bold;
}

.tvb_event {
  position: relative;
  height: 17px;
  width: 310px;
}

.tvb_event_past_date {
  position: absolute;
  top: 0;
  left: 15px;
  width: 35px;
  color: #adadad;
}

.tvb_event_past_name {
  position: absolute;
  top: 0;
  left: 50px;
  width: 235px;
  color: #adadad;
}

.tvb_upcoming_event {
  position: relative;
  height: 15px;
  width: 310px;
}

.tvb_event_upcoming_date {
  position: absolute;
  top: 0;
  left: 15px;
  width: 35px;
}

.tvb_event_upcoming_name {
  position: absolute;
  top: 0;
  left: 50px;
  width: 235px;
}

.tvb_link {
  position: relative;
  margin-left: 15px;
  height: 15px;
  width: 235px;
  margin-top: 10px;
}

/* lines */
.line_purple {
  background-color: #cc2336;
  height: 9px;
  width: 300px;
}

.line_grey {
  background-color: #464646;
  height: 9px;
  width: 300px;
}

.line_blue {
  background-color: #2c86c8;
  height: 9px;
  width: 300px;
}

/* tabla */

.tbl_lrg {
  position: relative;
  width: 640px;
  color: #000000;
}

.tbl_lrg_days {
  position: relative;
  height: 40px;
  width: 640px;
  color: #000000;
}

.tbl_lrg_header {
  position: relative;
  height: 45px;
  width: 630px;
  margin: 15px 10px 10px 10px;
  font-family: Trebuchet MS;
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  border-bottom: 1px solid #eeeeee;
}

.tbl_lrg_header_icons {
  position: absolute;
  top: 0;
  right: 0;
}

.tbl_lrg_event {
  position: relative;
  width: 630px;
  margin: 10px 10px 20px 10px;
  background-color: #ffffff;
}

.tbl_lrg_event_time {
  position: relative;
  font-family: Trebuchet MS;
  line-height: 11px;
  font-size: 11px;
  font-weight: normal;
  color: #000000;
}

.tbl_lrg_event_name {
  position: relative;
  font-family: Trebuchet MS;
  font-size: 13px;
  font-weight: bold;
  color: #000000;
}

.tbl_lrg_event_description {
  position: relative;
  font-family: Trebuchet MS;
  font-size: 11px;
  font-weight: normal;
  color: #000000;
}

.tbl_lrg_event_icons {
  position: absolute;
  top: 0;
  right: 0;
}

.tbl_lrg_event_active {
  position: relative;
  width: 630px;
  margin: 10px 10px 10px 10px;
  background-color: #ffffff;
}

.tbl_lrg_event_time_active {
  position: relative;
  font-family: Trebuchet MS;
  line-height: 11px;
  font-size: 11px;
  font-weight: normal;
  color: #000000;
}

.tbl_lrg_event_name_active {
  position: relative;
  font-family: Trebuchet MS;
  font-size: 13px;
  font-weight: bold;
  color: #000000;
}

.tbl_lrg_event_description_active {
  position: relative;
  font-family: Trebuchet MS;
  font-size: 11px;
  font-weight: normal;
  color: #000000;
}

.tbl_lrg_event_past {
  position: relative;
  width: 630px;
  margin: 10px 10px 10px 10px;
  background-color: #ffffff;
}

.tbl_lrg_event_time_past {
  position: relative;
  font-family: Trebuchet MS;
  line-height: 11px;
  font-size: 11px;
  font-weight: normal;
  color: #bbbdbe;
}

.tbl_lrg_event_name_past {
  position: relative;
  font-family: Trebuchet MS;
  font-size: 13px;
  font-weight: bold;
  color: #bbbdbe;
}

.tbl_lrg_event_description_past {
  position: relative;
  font-family: Trebuchet MS;
  font-size: 12px;
  font-weight: normal;
  color: #bbbdbe;
}

/* -------------------------------------------------------------------------------------------- */

div {
  font-family: Trebuchet MS;
  font-weight: normal;
  color: #000000;
  font-size: 12px;
}

td {
  font-family: Trebuchet MS;
  font-weight: normal;
  font-size: 12px;
}

input {
  font-family: Tahoma;
  font-weight: normal;
  color: #000000;
  font-size: 9px;
  border: 0;
}

select {
  font-family: Tahoma;
  font-weight: normal;
  color: #000000;
  font-size: 9px;
  border: 0;
  height: 12px;
}

option {
  font-family: Tahoma;
  font-weight: normal;
  color: #000000;
  font-size: 9px;
  border: 0;
}

a {
  color: #000000;
  text-decoration: none;
}

a:hover {
  color: #000000;
  text-decoration: underline;
}

.puff {
  margin-bottom: 10px;
  padding: 5px;
  font-family: Trebuchet MS;
  font-weight: normal;
  color: #000000;
  line-height: 11px;
  font-size: 11px;
}

a.puffLink {
  font-family: Trebuchet MS;
  font-weight: normal;
  color: #000000;
  line-height: 11px;
  font-size: 11px;
  text-decoration: none;
}

a.puffLink:hover {
  font-family: Trebuchet MS;
  font-weight: normal;
  color: #000000;
  line-height: 11px;
  font-size: 11px;
  text-decoration: underline;
}

a.inactiveEvent {
  font-family: Trebuchet MS;
  font-size: 11px;
  text-decoration: none;
}

a.inactiveEvent:hover {
  font-family: Trebuchet MS;
  font-size: 11px;
  text-decoration: underline;
}

a.activeEvent {
  color: #ffffff;
  font-family: Trebuchet MS;
  font-size: 11px;
  text-decoration: none;
}

a.activeEvent:hover {
  color: #ffffff;
  font-family: Trebuchet MS;
  font-size: 11px;
  text-decoration: underline;
}

form {
  margin: 0;
  padding: 0;
}

b.no {
  display: none;
}

.displaynone {
  display: none;
}

/* TvBoxes */

h2.tvb_chartname {
  height: 26px;
  margin-left: 15px;
  vertical-align: middle;
  font-family: Trebuchet MS;
  font-size: 12px;
  font-weight: bold;
  color: #000000;
}

ul.tvb_chart {
  position: relative;
  width: 310px;
  list-style-type: none;
  margin: 10px 0 10px 0;
  padding: 0;
}

ul.tvb_chart li {
  margin: 0;
  padding: 0;
}

ul.tvb_chart li p {
  font-family: Trebuchet MS;
  font-size: 11px;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

li.tvb_event_active {
  position: relative;
  height: 15px;
  width: 310px;
  background-color: #61b8e3;
  color: #ffffff;
}

li.tvb_event_active p {
  color: #ffffff;
  font-weight: bold;
}


li.tvb_event_activesub {
  position: relative;
  height: 15px;
  width: 310px;
  color: #adadad;
  background-color: #ffffff;
}

li.tvb_event_activesub p {
  color: #adadad;
  font-family: Trebuchet MS;
  font-size: 11px;
}

li.tvb_event {
  position: relative;
  height: 17px;
  width: 310px;
}

li.tvb_event p {
  color: #adadad;
}

li.tvb_upcoming_event {
  position: relative;
  height: 15px;
  width: 310px;
}

li.tvb_link {
  position: relative;
  margin-left: 15px;
  height: 15px;
  width: 235px;
  margin-top: 10px;
}

.ad-div-top {
  margin-top: 10px;
}

.ad-div-right {
  margin-top: 10px;
  margin-bottom: 10px;
}
