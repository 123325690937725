@import '../../style/constant';
@import '../../style/mixins';


.detail-modal {
    height: 100%;
    .modal-header{ 
        display: block;
        padding: 0;
        h5{
            color: #fff;
            background: url('../../images/new/grid_program_active_bg.gif') 0 0 repeat-x;
            padding: 10px 20px;
            font-size: 1rem;
        }
        .close{
            margin: 7px -5px 0 0;
            right: 0;
            top: 0;
            background: none;
            font-size: 1rem;
            cursor: pointer;
        }
        .left-logo{
            display: inline-block;
            vertical-align: middle;
        }
    }
    .meta{
        color: #555555;
        padding:0 20px;
        display: flex;
        align-items: center;
    }
    .name{
        margin-left: 10px;
        .text{
            color: #555555;
            font-weight: 900;
            display: block;
            font-size: 1rem;
        }
        .time{
            font-size: 0.8rem;
        }
    }
    .modal-content{
        padding: 20px 20px 60px 20px;
        border:0;
        height: calc(100% - 160px);
        overflow-y: auto;
    }
    p{margin-bottom: 20px;}
    .meta-btm{
        display: flex;
        margin-bottom: 20px;
        span{
            &:first-child{
                border: 0;
                padding-left:0;
            }
            padding: 0  10px;
            border-left:1px solid #555555;
            color: #555555;
        }
        
    }
    .modal-footer{
        display: block;
        .form-group{
            margin-bottom: 5px;
        }
        .form-control{
            border-radius: 0;
            line-height: 1rem;
            height: 30px;
        }
        textarea{
            height: 50px;
            width: 100%;
        }
    }
}


.mail-to{
    width: 100%;
    clear: both;
    
}

.action-list{
    list-style: none;
    margin: 0;
    padding:0;
    li{
        margin: 5px;
        cursor: pointer;
        display: inline-block;
        span{
            margin-right: 5px;
        }
    }
}

@include respond-above(md) {
    .detail-modal{
        .modal-dialog{
            min-width: 700px;
        }
    }
  }